import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 模型id
    modelId: "",
    // seo优化
    seoTitle: "",
    seoKeyword: "",
    seoDesc: "",
    // 留言板开关
    boardShow: "",
    // 留言板选项
    boardBar: [],
    // 悬浮框开关
    levitateShow: "",
    // 产品分类列表
    cateList: [],
    // 产品参数列表
    paramList: [],
    // 案例分类列表
    caseCateList: [],
    // 新闻分类列表
    newsCateList: [],
  },
  getters: {
    //获取模型id
    modelId: (state) => state.modelId,
    //获取seo优化信息
    seoContent(state) {
      return {
        seoTitle: state.seoTitle,
        seoKeyword: state.seoKeyword,
        seoDesc: state.seoDesc,
      }
    },
    //获取留言板功能信息
    boardShow: (state) => state.boardShow,
    boardBar: (state) => state.boardBar,
    levitateShow: (state) => state.levitateShow,
    // 获取产品分类列表
    cateList: (state) => state.cateList,
    // 获取产品参数列表
    paramList: (state) => state.paramList,
    // 获取案例分类列表
    caseCateList: (state) => state.caseCateList,
    // 获取案例分类列表
    newsCateList: (state) => state.newsCateList,
  },
  mutations: {
    //记录模型id
    setModelId(state, message) {
      state.modelId = message
    },
    //记录模型id
    setSeoContent(state, message) {
      state.seoTitle = message.seoTitle
      state.seoKeyword = message.seoKeyword
      state.seoDesc = message.seoDesc
    },
    //记录留言板功能信息
    setBoardData(state, message) {
      state.boardShow = message.boardShow ? true : false
      state.boardBar = message.boardBar ? JSON.parse(message.boardBar) : []
      state.levitateShow = message.levitateShow ? true : false
    },
    // 记录产品分类列表
    setCateList(state, message) {
      state.cateList = message
    },
    // 记录产品参数列表
    setParamList(state, message) {
      state.paramList = message
    },
    // 记录案例分类列表
    setCaseCateList(state, message) {
      state.caseCateList = message
    },
    // 记录新闻分类列表
    setNewsCateList(state, message) {
      state.newsCateList = message
    },
  },
  actions: {},
})
