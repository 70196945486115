import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import "./utils/rem"
import echarts from "echarts"
import md5 from "js-md5"
import BaiduMap from "vue-baidu-map"

import "element-ui/lib/theme-chalk/index.css"

Vue.use(BaiduMap, {
  ak: "yzA4IfNHf8SMRPomH0tOiFOpiFwuMEAa",
})
Vue.config.productionTip = false
Vue.use(ElementUI)

// Vue.prototype.$imgUrl = 'https://api.513dvr.com'
Vue.prototype.$imgUrl = "/api"
Vue.prototype.$echarts = echarts
Vue.prototype.$md5 = md5

// 全局路由导航守卫
router.beforeEach((to, from, next) => {
  if (!to.meta.isLogin) {
    next()
  }
  if (sessionStorage.getItem("authorization")) {
    next()
  } else {
    if (to.fullPath != "/login") {
      ElementUI.Message({
        type: "error",
        message: "暂无权限访问该系统，请重新登录",
        duration: 1000,
      })

      next("/")
    }
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
