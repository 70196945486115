import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// 登录
const Login = () =>
  import(/*webpackChunkName:"Login"*/ "@/views/login/Login.vue");
// 首页
const Admin = () => import(/*webpackChunkName:"Admin"*/ "@/views/Admin.vue");
// 概况
const Basics = () =>
  import(/*webpackChunkName:"Home"*/ "@/views/basics/Basics.vue");
// 产品管理
const Product = () =>
  import(/*webpackChunkName:"Product"*/ "@/views/product/Product.vue");
// 案例管理
const Case = () => import(/*webpackChunkName:"Case"*/ "@/views/case/Case.vue");
// 新闻管理
const News = () => import(/*webpackChunkName:"News"*/ "@/views/news/News.vue");
// 互动管理
const Board = () =>
  import(/*webpackChunkName:"Board"*/ "@/views/board/Board.vue");
// 百度优化
const Optimize = () =>
  import(/*webpackChunkName:"Optimize"*/ "@/views/optimize/Optimize.vue");
// 网站统计
const Statistics = () =>
  import(/*webpackChunkName:"Statistics"*/ "@/views/statistics/Statistics.vue");
// 操作日志
const UserLog = () =>
  import(/*webpackChunkName:"UserLog"*/ "@/views/userLog/UserLog.vue");

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      isShow: false,
      isLogin: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "登录",
      isShow: false,
      isLogin: false,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    redirect: "/admin/basics",
    children: [
      {
        path: "basics",
        name: "Basics",
        component: Basics,
        meta: {
          title: "概况",
          isShow: true,
          isLogin: true,
        },
      },
      {
        path: "product",
        name: "Product",
        component: Product,
        meta: {
          title: "产品中心",
          isShow: true,
          isLogin: true,
        },
      },
      {
        path: "case",
        name: "Case",
        component: Case,
        meta: {
          title: "案例中心",
          isShow: true,
          isLogin: true,
        },
      },
      {
        path: "news",
        name: "News",
        component: News,
        meta: {
          title: "新闻中心",
          isShow: true,
          isLogin: true,
        },
      },
      {
        path: "board",
        name: "Board",
        component: Board,
        meta: {
          title: "互动管理",
          isShow: true,
          isLogin: true,
        },
      },
      {
        path: "optimize",
        name: "Optimize",
        component: Optimize,
        meta: {
          title: "百度优化",
          isShow: true,
          isLogin: true,
        },
      },
      {
        path: "statistics",
        name: "Statistics",
        component: Statistics,
        meta: {
          title: "网站统计",
          isShow: true,
          isLogin: true,
        },
      },
      {
        path: "userLog",
        name: "UserLog",
        component: UserLog,
        meta: {
          title: "操作日志",
          isShow: true,
          isLogin: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
